var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('div', {
    staticClass: "DAV-dropdown"
  }, [_c('button', {
    staticClass: "DAV-dropdown__button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.isVisible = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.selected) + " ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isVisible,
      expression: "isVisible"
    }],
    staticClass: "DAV-dropdown__list DAV-dropdown__list--overlay"
  }, [_c('div', {
    staticClass: "DAV-dropdown__selected"
  }, [_vm._v(" " + _vm._s(_vm.selected) + " ")]), _c('ul', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside:toggleLayer",
      arg: "toggleLayer"
    }]
  }, _vm._l(_vm.option.componentOption.properties[0].elements.options, function (value, index) {
    return _c('li', {
      key: index
    }, [_c('button', {
      class: {
        'select-active': value === _vm.selected
      },
      attrs: {
        "type": "button",
        "value": value
      },
      on: {
        "click": function click($event) {
          return _vm.updateOption($event);
        }
      }
    }, [_vm._v(" " + _vm._s(value) + " ")])]);
  }), 0)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }