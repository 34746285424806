<template>
  <div>
    <div class="frmBlock__layout">
      <div class="DAV-dropdown">
        <button
          type="button"
          class="DAV-dropdown__button"
          @click.stop="isVisible = true"
        >
          {{ selected }}
        </button>
        <div
          class="DAV-dropdown__list DAV-dropdown__list--overlay"
          v-show="isVisible"
        >
          <div class="DAV-dropdown__selected">
            {{ selected }}
          </div>
          <ul v-click-outside:toggleLayer>
            <li
              v-for="(value, index) in option.componentOption.properties[0]
                .elements.options"
              :key="index"
            >
              <button
                type="button"
                :value="value"
                @click="updateOption($event)"
                :class="{'select-active': value === selected}"
              >
                {{ value }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      isVisible: false,
      selected: ""
    };
  },
  computed: {
    initialValue() {
      return this.$route.path === "/option"
        ? this.option.componentOption.properties[0].initialValue
        : this.option.value.text;
    }
  },
  mounted() {
    this.selected = this.initialValue;
    if (this.$route.path === "/option") {
      this.updateProperty(this.initialValue);
    }
  },
  methods: {
    // 레이어 Toggle
    toggleLayer(value) {
      this.isVisible = !value ? value : !this.isVisible;
    },
    // 프로퍼티 업데이트 (파트너)
    updateProperty(value) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: this.option.componentOption.properties[0].id,
            initialValue: value
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateOption(e) {
      this.isVisible = false;
      this.selected = e.target.innerText.trim();
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[0].key,
        e.target.innerText.trim()
      );
      if (this.$route.path === "/option") {
        this.updateProperty(e.target.innerText.trim());
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
